<template>
    <ConfirmDialog group="dialog" />
    <div class="p-grid">
        <div class="p-col-12">
            <div class="card p-fluid">
                <h4><strong>Bulletin Category</strong></h4>
                <div class="p-field p-grid">
                    <label for="name_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Name:</label>
                    <div class="p-col-12 p-md-10">
                        <!-- <p>{{ bulletinCategoryDetail. }}</p> -->
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Email:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ email }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="name_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Phone:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ mobile }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_ko" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Country:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ country_name }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Status:</label>
                    <div class="p-col-12 p-md-10">
                        <p>{{ status }}</p>
                    </div>
                </div>

                <div class="p-field p-grid">
                    <label for="description_en" class="p-col-12 p-mb-2 p-md-2 p-mb-md-0">Creation Date & Time:</label>
                    <div class="p-col-12 p-md-10">
                        <!-- <p>{{ mydata.description_en }}</p> -->
                    </div>
                </div>

                <div class="p-d-flex p-jc-end" style="float: left">
                    <router-link :to="'/ManagerManagementEdit/' + $route.params.id"
                        ><Button label="help" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2"><i class="pi pi-user-edit p-mr-2"></i> Edit </Button></router-link
                    >
                    <!-- <Button @click="del($route.params.id)" label="delete" class="p-button-outlined p-button-danger p-mr-2 p-mb-2"><i class="pi pi-trash p-mr-2"></i>{{$t('button.delete')}}</Button> -->
                </div>

                <div class="p-d-flex p-jc-end p-ai-center">
                    <div>
                        <Button label="Back" icon="pi pi-replay" iconPos="left" class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import axios from 'axios';
import BulletinCategoryManagementService from '../../../service/API/SuperAdmin/BulletinCategoryManagementService.js';
import { useRoute } from 'vue-router';
export default {
    data() {
        return {
            bulletinCategoryDetail: {},
        };
    },
    created() {
        this.bulletinCategoryManagementService = new BulletinCategoryManagementService();
    },

    mounted() {
        const route = useRoute();
        console.log(route.params);

        this.bulletinCategoryManagementService
            .getBulletinCategoryManagementView(route.params.id)
            .then((res) => {
                console.log(res.data.data, 'responce');

                this.bulletinCategoryDetail = res.data.data[0];
            })

            .catch((err) => console.log(err));
    },
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;
   
}
</style>